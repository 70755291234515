/*********************
OPACITY
adds an opacity to an HTML element
USAGE:  @include opacity(.8,80);
*********************/

/*********************
HTML LIST ITEM WITH NOT LIST STYLE
removes the list-style settings from an HTML list
USAGE:  @include listNoStyle
*********************/

/*********************
PLACHOLDER STYLING
*********************/

/**************************
HEXCODE TO RGBA
***************************/

/*********************
KEYFRAME ANIMATION

animation-name: bouncing-arrow;
animation-duration: .6s;
animation-timing-function: ease-out;
animation-delay: 0;
animation-direction: alternate;
animation-iteration-count: infinite;
animation-fill-mode: none;
animation-play-state: running;

the above can be written like:
@include animation('move-the-object .6s ease-out 0s alternate infinite');
*********************/

/*********************
KEYFRAMES
*********************/

/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
This is a great tool for creating CSS gradients:  http://www.colorzilla.com/gradient-editor/
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */

/* @include css-gradient-triangle(top, bottom, #1F1C21); */

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-horizontal(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-diagonal-lefttop-rightbottom(#dfdfdf,0%, #f8f8f8, 80%); */

/*********************
Greyscale
greyscale USAGE:  @include greyscale(30%)
*********************/

.facetwp-checkbox.checked {
  background-image: url(../img/checkbox-on.png) !important;
  background-position: 0 5px;
}

.faq-link {
  margin-top: 10px;
  margin-bottom: 5px;
  border: 2px solid rgba(19, 114, 206, 0.75) !important;
  color: #1372ce !important;
  visibility: visible;
  background-color: transparent;
  text-transform: capitalize;
}

.faq-link:hover {
  background-color: #fc6901 !important;
  border-color: #fc6901 !important;
  color: #ffffff !important;
}

.wpb-js-composer .wpb_wrapper .toggles {
  margin-bottom: 0px;
}

.wpb-js-composer .wpb_wrapper .toggles .toggle {
  margin-bottom: 0px !important;
}

.wpb-js-composer div[data-style="default"] .toggle h3 a {
  line-height: 1.2em;
}

.wpb-js-composer div[data-style="default"] .toggle h3 a i {
  right: 10px !important;
}

.text-blue {
  color: #1372ce;
}