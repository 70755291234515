/*********************
OPACITY
adds an opacity to an HTML element
USAGE:  @include opacity(.8,80);
*********************/

/*********************
HTML LIST ITEM WITH NOT LIST STYLE
removes the list-style settings from an HTML list
USAGE:  @include listNoStyle
*********************/

/*********************
PLACHOLDER STYLING
*********************/

/**************************
HEXCODE TO RGBA
***************************/

/*********************
KEYFRAME ANIMATION

animation-name: bouncing-arrow;
animation-duration: .6s;
animation-timing-function: ease-out;
animation-delay: 0;
animation-direction: alternate;
animation-iteration-count: infinite;
animation-fill-mode: none;
animation-play-state: running;

the above can be written like:
@include animation('move-the-object .6s ease-out 0s alternate infinite');
*********************/

/*********************
KEYFRAMES
*********************/

/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
This is a great tool for creating CSS gradients:  http://www.colorzilla.com/gradient-editor/
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */

/* @include css-gradient-triangle(top, bottom, #1F1C21); */

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-horizontal(#dfdfdf,0%, #f8f8f8, 80%); */

/* @include css-gradient-two-diagonal-lefttop-rightbottom(#dfdfdf,0%, #f8f8f8, 80%); */

/*********************
Greyscale
greyscale USAGE:  @include greyscale(30%)
*********************/

.post-content {
  padding-left: 0;
}

.post-content .article-content-wrap .post-content-wrap .read-more-link {
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1), transform 0.45s cubic-bezier(0.25, 1, 0.33, 1), border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1), color 0.45s cubic-bezier(0.25, 1, 0.33, 1), background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 1.1;
  border-color: #0693e3;
  border-width: 2px;
  border-style: solid;
  border-radius: 200px !important;
  color: #0693e3;
  margin-top: 20px;
}

.post-content .article-content-wrap .post-content-wrap .read-more-link:hover,
.post-content .article-content-wrap .post-content-wrap .read-more-link:focus {
  border-color: #fc6901;
}